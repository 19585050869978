import React, { useState } from "react";

const LoginPage = ({ setToken }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  /*   
  Submit mit E-Mail Überprüfung
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      //  setError("Bitte geben Sie eine gültige E-Mail-Adresse ein.");
    } else {
      setError("");
      fetch("https://process-server.videobomb.de/screenshot_checker_login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_name: email,
          password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          if (data?.error === false) {
            setToken(data.token);
            return;
          }
          setError(data.message);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      console.log(`E-Mail: ${email}, Passwort: ${password}`);
    }
  }; */

  const handleSubmit = (e) => {
    e.preventDefault();

    setError("");
    fetch("https://process-server.videobomb.de/screenshot_checker_login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_name: email,
        password,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        if (!data?.error) {
          setToken(data["videocomet-server-access-token"]);
          return;
        }
        setError(data.message);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
        backgroundColor: "#f0f0f0",
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "400px",
          padding: "20px",
          borderRadius: "4px",
          backgroundColor: "#fff",
        }}
      >
        <input
          type="text"
          placeholder="E-Mail-Adresse"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{
            marginBottom: "10px",
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #ddd",
          }}
        />
        <input
          type="password"
          placeholder="Passwort"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{
            marginBottom: "10px",
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #ddd",
          }}
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
        <button
          type="submit"
          style={{
            padding: "10px",
            borderRadius: "4px",
            border: "none",
            backgroundColor: "#007bff",
            color: "#fff",
          }}
        >
          Anmelden
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
